/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { debounce, throttle } from "lodash";
import { preloadImage } from "../../../Utils";
import * as Scroll from "react-scroll";

// context
import AppContext from "../../../Store/AppContext";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function ProjectComponent(props) {
  // throttle
  const handleScroll = useCallback(throttle(_handleScroll, 2000), []);
  // data
  const app = useContext(AppContext);
  const { imagesPath } = app.state;
  const { project, projects } = props;
  const { sections } = project;
  const _project = useRef(project);
  // local state
  // general
  const [loading, setLoading] = useState(true);
  const [preloadImages, setPreloadImages] = useState([
    {
      project: "smarttrust",
      images: [
        `${imagesPath}smarttrust/logo.png`,
        `${imagesPath}smarttrust/devices.png`,
      ],
    },
    {
      project: "kim",
      images: [`${imagesPath}kim/logo.png`, `${imagesPath}kim/devices.png`],
    },
    {
      project: "cryptoswap",
      images: [
        `${imagesPath}cryptoswap/logo.png`,
        `${imagesPath}cryptoswap/devices.png`,
      ],
    },
  ]);
  const _preloadImages = useRef(preloadImages);
  const [preloadedImages, _setPreloadedImages] = useState([]);
  const _preloadedImages = useRef(preloadedImages);
  // visuals section
  // const [visualsActivePage, setVisualsActivePage] = useState(1);
  const [clickedVisualsItem, setClickedVisualsItem] = useState({});

  useEffect(() => {
    // listeners
    window.addEventListener("scroll", handleScroll);
    // set lists according device
    setLists();
    // preload images
    (async () => {
      try {
        if (project && project.id) {
          const preloadProjectImages = preloadImages.find(
            (item) => item.project === project.key
          )
            ? preloadImages.find((item) => item.project === project.key).images
            : "";
          if (preloadProjectImages) {
            let preloadedProjectImages = [];
            for (let img of preloadProjectImages) {
              let res = await preloadImage(img);
              preloadedProjectImages.push(res);
            }
            setPreloadedImages([
              {
                project: project.key,
                images: preloadedProjectImages,
              },
            ]);
            const id = setTimeout(() => {
              clearTimeout(id);
              setLoading(false);
            }, 300);
          }
        }
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    _project.current = project;
  }, [project]);

  useEffect(() => {
    _preloadImages.current = preloadImages;
  }, [preloadImages]);

  /**
   * Set preloaded images
   *
   * @param {any} val
   */
  function setPreloadedImages(val) {
    _setPreloadedImages(val);
    _preloadedImages.current = val;
  }

  /**
   * Handle scroll
   */
  function _handleScroll() {
    const offset = window.pageYOffset;
    const scrollHeight = document.body.scrollHeight;
    if (offset > scrollHeight / 3) {
      prepareProjects();
    }
  }

  /**
   * Preload images
   *
   * @param {object} images
   */
  async function fnPreloadImages(images) {
    let preloadedImages = [];
    for (let img of images) {
      const res = await preloadImage(img);
      preloadedImages.push(res);
    }
    return preloadedImages;
  }

  /**
   * Set lists
   */
  function setLists() {
    let listsHor = Array.from(document.querySelectorAll(".project-list-hor"));
    for (let list of listsHor) {
      const listWidth = list.getBoundingClientRect().width;
      const listItems = Array.from(list.querySelectorAll("li"));
      for (let [index, listItem] of listItems.entries()) {
        const prevListItem = index ? listItems[index - 1] : "";
        const prevListItemProp = prevListItem
          ? prevListItem.getBoundingClientRect()
          : "";
        const listItemProp = listItem.getBoundingClientRect();
        if (
          prevListItemProp &&
          prevListItemProp.top &&
          Math.round(prevListItemProp.top) !== Math.round(listItemProp.top)
        ) {
          prevListItem.classList = "r";
        }
      }
    }
  }
  /**
   * Toggle gallery overlay
   *
   * @param {string} action
   */
  const toggleGalleryOverlay = (section, action) => {
    const i = action === "prev" ? -1 : 1;
    if (section.gallery.find((item) => item.id === clickedVisualsItem.id + i)) {
      setClickedVisualsItem(
        section.gallery.find((item) => item.id === clickedVisualsItem.id + i)
      );
    }
  };

  /**
   * Handle click on scroll icon
   */
  function handleClickOnScrollIcon() {
    const top = document.querySelector(".description")
      ? document.querySelector(".description").offsetTop
      : 0;
    Scroll.animateScroll.scrollTo(top, {
      duration: 800,
      smooth: true,
      ignoreCancelEvents: true,
    });
  }

  /**
   * Render section
   */
  const renderSection = (section) => {
    switch (section.key) {
      case "intro":
        return (
          <div
            className={`container project-section intro ${project.key}-intro`}
          >
            <span
              className="portfolio-top-nav-btn portfolio-top-nav-btn-left intro-nav-btn intro-nav-btn-left"
              onClick={() => props.onHandleNav("prev")}
              style={project.id === 1 ? { display: "none" } : {}}
            >
              <img src={`${imagesPath}/left-arrow.svg`} />
            </span>
            <span
              className="portfolio-top-nav-btn portfolio-top-nav-btn-right intro-nav-btn intro-nav-btn-right"
              onClick={() => props.onHandleNav("next")}
              style={
                project.id === Math.max(...projects.map((item) => item.id))
                  ? { display: "none" }
                  : {}
              }
            >
              <img src={`${imagesPath}/right-arrow.svg`} />
            </span>

            <div className="intro-devices">
              <img src={`${imagesPath}${project.key}/devices.png`} alt="logo" />
            </div>
            <div className="intro-heading">
              <h3>{section.title}</h3>
              <p>{section.subTitle}</p>
            </div>
            {/*
            <img
              src={`${imagesPath}/mouse-scroll.svg`}
              className="intro-icon"
              alt="icon"
            /> */}
            <svg
              className="intro-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="44"
              viewBox="0 0 20 44"
              fill="none"
              onClick={handleClickOnScrollIcon}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.58809 9.53536V23.8181C1.58809 28.2092 5.14262 31.7648 9.52927 31.7648C13.9153 31.7648 17.4704 28.2075 17.4704 23.8181V9.53536C17.4704 5.14432 13.9159 1.58866 9.52927 1.58866C5.14319 1.58866 1.58809 5.14602 1.58809 9.53536ZM0 9.53536C0 4.26909 4.26526 0 9.52927 0C14.7921 0 19.0585 4.26696 19.0585 9.53536V23.8181C19.0585 29.0844 14.7933 33.3535 9.52927 33.3535C4.2664 33.3535 0 29.0865 0 23.8181V9.53536Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.73511 5.55912V8.7353C8.73511 9.17402 9.09073 9.52964 9.52945 9.52964C9.96817 9.52964 10.3238 9.17402 10.3238 8.7353V5.55912C10.3238 5.12039 9.96817 4.76477 9.52945 4.76477C9.09073 4.76477 8.73511 5.12039 8.73511 5.55912Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.52912 42.0828L13.8969 37.7156C14.2066 37.4054 14.7095 37.4054 15.0197 37.7156C15.3299 38.0253 15.3299 38.5282 15.0197 38.8384L10.0908 43.7674C9.93571 43.9225 9.73243 44 9.52914 44C9.32584 44 9.12255 43.9225 8.96746 43.7674L4.00096 38.8009C3.69076 38.4907 3.69076 37.9877 4.00096 37.6775C4.31116 37.3678 4.81358 37.3678 5.12379 37.6775L9.52912 42.0828Z"
                fill="black"
              />
            </svg>
          </div>
        );
        break;
      case "description":
        return (
          <div
            className={`project-section description ${project.key}-description`}
          >
            <div className="container description-content">
              <h4 className="project-heading">{section.title}</h4>
              <h6
                className="project-paragraph description-sub-heading"
                dangerouslySetInnerHTML={{ __html: section.subTitle }}
              />
              {section.list && section.list.length > 0 && (
                <ul className="project-list-ver">
                  {section.list.map((item, i) => {
                    return (
                      <li key={i}>
                        <div></div>
                        <small></small>
                        <p dangerouslySetInnerHTML={{ __html: item }}></p>
                      </li>
                    );
                  })}
                </ul>
              )}
              {section.image && (
                <img
                  className="description-image"
                  src={section.image.url}
                  alt="bck"
                  loading="lazy"
                />
              )}
            </div>
          </div>
        );
        break;
      case "visuals":
        return (
          <div className={`project-section visuals ${project.key}-visuals`}>
            <div className="container visuals-content">
              <h4 className="project-heading">{section.title}</h4>

              <div className="visuals-gallery">
                {clickedVisualsItem && clickedVisualsItem.id && (
                  <div className="visuals-gallery-overlay">
                    <div className="visuals-gallery-overlay-inner">
                      <div
                        className="visuals-gallery-overlay-close"
                        onClick={() => setClickedVisualsItem({})}
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="close"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                      </div>
                      {section.gallery.find(
                        (item) => item.id === clickedVisualsItem.id - 1
                      ) && (
                        <div
                          className="visuals-gallery-overlay-prev"
                          onClick={() => toggleGalleryOverlay(section, "prev")}
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="left"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                          </svg>
                        </div>
                      )}

                      <img
                        src={clickedVisualsItem.url}
                        alt={clickedVisualsItem.name}
                        loading="lazy"
                      />
                      <span>{clickedVisualsItem.name}</span>
                      {section.gallery.find(
                        (item) => item.id === clickedVisualsItem.id + 1
                      ) && (
                        <div
                          className="visuals-gallery-overlay-next"
                          onClick={() => toggleGalleryOverlay(section, "next")}
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="right"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <Swiper
                  className="visuals-gallery-inner"
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={30}
                  slidesPerView={1}
                  breakpoints={{
                    600: { slidesPerView: 2 },
                    767: { slidesPerView: 3 },
                    992: { slidesPerView: 4 },
                  }}
                  //navigation
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  {section.gallery &&
                    section.gallery.length > 0 &&
                    section.gallery.map((item, i) => (
                      <SwiperSlide
                        className="visuals-gallery-item"
                        key={i}
                        onClick={() => setClickedVisualsItem(item)}
                      >
                        <div className="overlay">
                          <span>{item.name}</span>
                        </div>
                        <img src={item.url} alt={item.name} loading="lazy" />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        );
        break;
      case "coding":
        return (
          <div className={`project-section coding ${project.key}-coding`}>
            <div className="container coding-content">
              <h4 className="project-heading">{section.title}</h4>
              <ul className="project-list-hor coding-list">
                {section.list &&
                  section.list.length > 0 &&
                  section.list.map((item, index) => {
                    return (
                      <li key={index}>
                        {item}
                        <small></small>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        );
        break;
      case "blockchain":
        return (
          <div
            className={`project-section blockchain ${project.key}-blockchain`}
          >
            <div className="container blockchain-content">
              <h4 className="project-heading blockchain-heading">
                {section.title}
              </h4>
              <div className="blockchain-heading-txt">
                <p className="project-paragraph">{section.subTitle}</p>
              </div>
              {section.blockchainTechList && (
                <div className="blockchain-tech-list">
                  {section.blockchainTechList.title && (
                    <h6 className="blockchain-sub-heading">
                      {section.blockchainTechList.title}
                    </h6>
                  )}
                  {section.blockchainTechList.items &&
                    section.blockchainTechList.items.length > 0 && (
                      <ul
                        className="project-list-hor"
                        style={
                          !section.smartContractFuncList
                            ? { paddingBottom: 0 }
                            : {}
                        }
                      >
                        {section.blockchainTechList.items.map((item, i) => (
                          <li key={i}>
                            {item}
                            <small></small>
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              )}
              {section.smartContractFuncList && (
                <div className="blockchain-contract-func">
                  <h6 className="blockchain-sub-heading">
                    {section.smartContractFuncList.title}
                  </h6>
                  {section.smartContractFuncList.items &&
                    section.smartContractFuncList.items.length > 0 && (
                      <div className="blockchain-contract-func-list">
                        {section.smartContractFuncList.items.map(
                          (item, index) => (
                            <div
                              className="blockchain-contract-func-list-i"
                              key={index}
                            >
                              <h6>{item.title}</h6>
                              <p>{item.text}</p>
                            </div>
                          )
                        )}
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
        );
        break;
      case "corporate":
        return (
          <div className={`project-section corporate ${project.key}-corporate`}>
            <div className="container corporate-content">
              <h4 className="project-heading">{section.title}</h4>
              {section.list && section.list.length > 0 && (
                <ul className="project-list-hor corporate-list">
                  {section.list.map((item, i) => (
                    <li key={i}>
                      {item}
                      <small></small>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        );
        break;
      case "media":
        return (
          <div className={`project-section media ${project.key}-media`}>
            <div className="container media-content">
              <h4 className="project-heading">{section.title}</h4>
              {section.list && section.list.length > 0 && (
                <div className="media-list">
                  {section.list.map((item, index) => {
                    return (
                      <div className="media-list-item" key={index}>
                        <div className="media-list-item-header">
                          <a href={item.videoUrl} target="_blank">
                            <img
                              src={item.thumbUrl}
                              alt="thumb"
                              loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="media-list-item-content">
                          <p>{item.text}</p>
                          <small>{item.videoDuration}</small>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
        break;
    }
  };

  /**
   * Prepare projects
   */
  const prepareProjects = async () => {
    // get newest state
    let project = _project.current;
    let preloadImages = _preloadImages.current;
    let preloadedImages = _preloadedImages.current;
    // local
    let preloadedImagesRes = [];
    // next project
    const nextProject = projects.find((item) => item.id === project.id + 1)
      ? projects.find((item) => item.id === project.id + 1)
      : "";
    //
    if (nextProject && nextProject.id) {
      const preloadProject = preloadImages.find(
        (item) => item.project === nextProject.key
      )
        ? preloadImages.find((item) => item.project === nextProject.key)
        : {};

      if (
        preloadProject.project &&
        !preloadedImages.find((item) => item.project === preloadProject.project)
      ) {
        const images = await fnPreloadImages(preloadProject.images);
        preloadedImagesRes = [{ project: nextProject.key, images }];
      }
    }
    // prev project
    const prevProject = projects.find((item) => item.id === project.id - 1)
      ? projects.find((item) => item.id === project.id - 1)
      : "";
    //
    if (prevProject && prevProject.id) {
      const preloadProject = preloadImages.find(
        (item) => item.project === prevProject.key
      )
        ? preloadImages.find((item) => item.project === prevProject.key)
        : {};
      if (
        preloadProject.project &&
        !preloadedImages.find((item) => item.project === preloadProject.project)
      ) {
        const images = await fnPreloadImages(preloadProject.images);
        preloadedImagesRes = [{ project: prevProject.key, images }];
      }
    }
    // save
    setPreloadedImages([...preloadedImages, ...preloadedImagesRes]);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className={`loader ${project.key}-loader`}>
          <div className="loader-inner">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              //xmlns:xlink="http://www.w3.org/1999/xlink"
              style={{
                margin: "auto",
                background: "none",
                display: "block",
                shapeRendering: "auto",
              }}
              width="200px"
              height="200px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#cccccc"
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                />
              </circle>
            </svg>
          </div>
        </div>
      )}

      <div className={`project ${project.key}`}>
        <div className="container project-header">
          <div className="logo">
            <img src={`${imagesPath}${project.key}/logo.png`} alt="logo" />
          </div>
        </div>
        <div className="project-body">
          {sections &&
            sections.length > 0 &&
            sections.map((section, i) => (
              <React.Fragment key={i}>{renderSection(section)}</React.Fragment>
            ))}
          <div className="project-section pre-footer">
            <div className="container pre-footer-content">
              <h4>
                For demo access, more information or investment opportunities
                contact us at
              </h4>
              <a href="mailto:enquiries@orka.limited">
                <h3>enquiries@orka.limited</h3>
              </a>
            </div>
          </div>
          <div className="project-section footer">
            <div className="container footer-content">
              <div className="footer-actions">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    props.onHandleNav("prev");
                  }}
                  style={
                    project.id === 1
                      ? { cursor: "not-allowed", background: "#eee" }
                      : {}
                  }
                >
                  <img
                    className="action-h"
                    src={`${imagesPath}but_prev_h.png`}
                    alt="button"
                    loading="lazy"
                  />
                  <img
                    className="action"
                    src={`${imagesPath}but_prev.png`}
                    alt="button"
                    loading="lazy"
                  />
                </a>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    props.onHandleNav("next");
                  }}
                  style={
                    project.id === Math.max(...projects.map((item) => item.id))
                      ? { cursor: "not-allowed", background: "#eee" }
                      : {}
                  }
                >
                  <img
                    className="action-h"
                    src={`${imagesPath}but_next_h.png`}
                    alt="button"
                    loading="lazy"
                  />
                  <img
                    className="action"
                    src={`${imagesPath}but_next.png`}
                    alt="button"
                    loading="lazy"
                  />
                </a>
              </div>
              <img
                src={`${imagesPath}logo_footer.svg`}
                alt="logo"
                loading="lazy"
              />
              <small>&#169; ORKA Limited, all rights reserved</small>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProjectComponent;
