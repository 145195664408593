import { Outlet, Navigate } from "react-router";
import { isJsonString } from "../../../Utils";

function ProtectedRoutes() {
  let auth = checkAuth();

  function checkAuth() {
    if (
      isJsonString(localStorage.getItem("authorized")) &&
      JSON.parse(localStorage.getItem("authorized")) === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  if (auth) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
}

export default ProtectedRoutes;
