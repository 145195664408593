/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-scroll";
// helmet
import { Helmet } from "react-helmet";
//import { debounce } from "lodash";
import { preloadImage } from "../../../Utils";
import { isMobile } from "react-device-detect";
// context
import AppContext from "../../../Store/AppContext";

function CrowningArtsComponent(props) {
  const app = useContext(AppContext);
  const { imagesPath } = app.state;
  //
  // data
  const { project, projects } = props;
  // const { sections } = project;

  // local state
  // general
  const [loading, setLoading] = useState(true);
  const [intro] = useState({
    nav: [
      {
        key: "design",
        title: "Design",
        mobileSections: false,
        scrollDuration: 500,
        scrollDurationM: 700,
        icon: (
          <svg
            //xmlns="http://www.w3.org/2000/svg"
            //xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 80 80"
            //style="enable-background:new 0 0 80 80;"
            //xml:space="preserve"
          >
            <style type="text/css"></style>
            <g id="Design__x2F__Design-Process__x2F__design-tool-pen">
              <g id="Group_11">
                <g id="design-tool-pen">
                  <path
                    id="Shape_92"
                    className="st0"
                    d="M19.4,38V16.2c0-6.9,5.6-12.5,12.5-12.5h6.2c6.9,0,12.5,5.6,12.5,12.5v46.7     c0,5.2,4.2,9.3,9.3,9.3c5.2,0,9.3-4.2,9.3-9.3v-6.2"
                  />
                  <path
                    id="Shape_93"
                    className="st0"
                    d="M35,59.8c0,8.6-7,15.6-15.6,15.6c-8.6,0-15.6-7-15.6-15.6V28.6c0-3.4,2.8-6.2,6.2-6.2h18.7     c3.4,0,6.2,2.8,6.2,6.2V59.8z"
                  />
                  <path id="Shape_94" className="st0" d="M3.8,38H35" />
                  <path
                    id="Shape_95"
                    className="st0"
                    d="M75.5,16.2v31.2H63V16.2h6.2H75.5L75.5,16.2z"
                  />
                  <path
                    id="Shape_96"
                    className="st0"
                    d="M63,16.2l6.2-12.5l6.2,12.5"
                  />
                  <path
                    id="Shape_97"
                    className="st0"
                    d="M75.5,47.3H63v3.1c0,3.4,2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2V47.3z"
                  />
                </g>
              </g>
            </g>
          </svg>
        ),
      },
      {
        key: "coding",
        title: "Coding",
        mobileSections: true,
        scrollDuration: 1300,
        scrollDurationM: 1600,
        icon: (
          <svg
            //xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 80 80"
            //style="enable-background:new 0 0 80 80;"
            //xml:space="preserve"
          >
            <g id="Programming-Apps-Websites__x2F__Programming__x2F__programming-browser">
              <g id="Group_2">
                <g id="programming-browser">
                  <path
                    id="Shape_7"
                    className="st0"
                    d="M51.1,39.5l9.1,9.1l-9.1,9.1"
                  />
                  <path
                    id="Shape_8"
                    className="st0"
                    d="M28.2,39.5l-9.1,9.1l9.1,9.1"
                  />
                  <path id="Shape_9" className="st0" d="M44.2,39.5l-9.1,18.3" />
                  <path
                    id="Rectangle-path_2"
                    className="st0"
                    d="M4.6,18.2c0-3.4,2.7-6.1,6.1-6.1h57.9c3.4,0,6.1,2.7,6.1,6.1v42.7     c0,3.4-2.7,6.1-6.1,6.1H10.7c-3.4,0-6.1-2.7-6.1-6.1V18.2z"
                  />
                  <path id="Shape_10" className="st0" d="M4.6,27.3h70.1" />
                  <path
                    id="Shape_11"
                    className="st0"
                    d="M15.2,19c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8     C14.5,19.3,14.8,19,15.2,19"
                  />
                  <path
                    id="Shape_12"
                    className="st0"
                    d="M24.4,19c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8     C23.6,19.3,24,19,24.4,19"
                  />
                  <path
                    id="Shape_13"
                    className="st0"
                    d="M33.5,19c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8     C32.8,19.3,33.1,19,33.5,19"
                  />
                </g>
              </g>
            </g>
          </svg>
        ),
      },
      {
        key: "blockchain",
        title: "Blockchain functionality integration",
        mobileSections: true,
        scrollDuration: 1300,
        scrollDurationM: 1800,
        icon: (
          <svg
            //xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 80 80"
            // style="enable-background:new 0 0 80 80;"
            // xml:space="preserve"
          >
            <g id="Programming-Apps-Websites__x2F__Plugins_x2F_Modules__x2F__module-three-1">
              <g id="Group_34">
                <g id="module-three-1">
                  <path
                    id="Shape_232"
                    className="st0"
                    d="M53.8,18c1,0.6,1.5,1.6,1.5,2.7v13.3L39.5,44.3L23.7,34.1V20.8c0-1.1,0.6-2.1,1.5-2.7     l12.7-7.6c1-0.6,2.3-0.6,3.3,0L53.8,18z"
                  />
                  <path id="Shape_233" className="st0" d="M39.5,28.5v15.8" />
                  <path
                    id="Shape_234"
                    className="st0"
                    d="M55.3,53.8l-15.8-9.5l15.8-10.2l14.4,9.2c0.9,0.6,1.4,1.6,1.4,2.7v11.7     c0,1.1-0.5,2.1-1.4,2.7L57,68.5c-1,0.7-2.4,0.7-3.4,0l-14.1-9.1V44.3"
                  />
                  <path id="Shape_235" className="st0" d="M55.3,53.8V69" />
                  <path
                    id="Shape_236"
                    className="st0"
                    d="M39.5,44.3v15.1l-14.1,9.1c-1,0.7-2.4,0.7-3.4,0L9.3,60.4c-0.9-0.6-1.5-1.6-1.5-2.7V46     c0-1.1,0.5-2.1,1.4-2.7l14.4-9.2L39.5,44.3l-15.8,9.5"
                  />
                  <path
                    id="Shape_237"
                    className="st0"
                    d="M23.7,53.8L8.2,44.5"
                  />
                  <path id="Shape_238" className="st0" d="M23.7,53.8V69" />
                  <path
                    id="Shape_239"
                    className="st0"
                    d="M39.5,28.5l-15.4-9.3"
                  />
                  <path
                    id="Shape_240"
                    className="st0"
                    d="M54.9,19.2l-15.4,9.3"
                  />
                  <path
                    id="Shape_241"
                    className="st0"
                    d="M70.8,44.5l-15.4,9.3"
                  />
                </g>
              </g>
            </g>
          </svg>
        ),
      },
      {
        key: "research",
        title: "Research, analysis & concept",
        mobileSections: true,
        scrollDuration: 1300,
        scrollDurationM: 2000,
        icon: (
          <svg
            // xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 80 80"
            // style="enable-background:new 0 0 80 80;"
            // xml:space="preserve"
          >
            <g id="Programming-Apps-Websites__x2F__Programming__x2F__programming-user-1">
              <g id="Group_19">
                <g id="programming-user-1">
                  <path
                    id="Shape_148"
                    className="st0"
                    d="M42.7,47.3h29.5c1.7,0,3.1-1.4,3.1-3.1V7c0-1.7-1.4-3.1-3.1-3.1H35"
                  />
                  <path id="Shape_149" className="st0" d="M19.5,36.4v9.3" />
                  <path
                    id="Shape_150"
                    className="st0"
                    d="M25.7,75.2l1.6-18.6H35V45.7c0-8.6-6.9-15.5-15.5-15.5C10.9,30.2,4,37.2,4,45.7v10.9h7.8     l1.6,18.6H25.7z"
                  />
                  <path
                    id="Shape_151"
                    className="st0"
                    d="M23.1,14.7c2.4,0,4.8-0.5,7.1-1.4c-0.6-4.6-4-8.3-8.6-9.2c-4.5-0.9-9.1,1.2-11.5,5.2     C13.5,12.8,18.2,14.7,23.1,14.7L23.1,14.7z"
                  />
                  <path
                    id="Oval_10"
                    className="st0"
                    d="M19.5,25.6c6,0,10.9-4.9,10.9-10.9S25.5,3.9,19.5,3.9S8.6,8.7,8.6,14.7S13.5,25.6,19.5,25.6z"
                  />
                  <path
                    id="Shape_152"
                    className="st0"
                    d="M41.2,16.3l7.8,7.8l-7.8,7.8"
                  />
                  <path id="Shape_153" className="st0" d="M56.7,25.6h12.4" />
                </g>
              </g>
            </g>
          </svg>
        ),
      },
    ],
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const preloadImages = [
          `${imagesPath}ca/ca_bgr.jpg`,
          `${imagesPath}ca/devices.png`,
          //  `${imagesPath}ca/devices_m.png`,
        ];
        for (let img of preloadImages) {
          const res = await preloadImage(img);
        }
        const id = setTimeout(() => {
          clearTimeout(id);
          setLoading(false);
        }, 300);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      {/*<Helmet>
        <title>ORKA.ooo | Case Study - CrowningVerse</title>
        <meta
          name="description"
          content="CrowningVerse - A different Universe"
        /> 

       <meta
          content="ORKA.ooo | Case Study - CrowningVerse"
          property="og:title"
        />
        <meta
          content="CrowningVerse - A different Universe"
          property="og:description"
        />
        <meta content="/favs/webclip-ooo.png" property="og:image" /> 

       <meta
          content="ORKA.ooo | Case Study - CrowningVerse"
          property="twitter:title"
        />
        <meta
          content="CrowningVerse - A different Universe"
          property="twitter:description"
        />
        <meta content="/favs/webclip-ooo.png" property="twitter:image" /> 
      </Helmet> */}
      {loading && (
        <div className="loader" style={{ background: "#21243a" }}>
          <svg
            // xmlns="http://www.w3.org/2000/svg"
            //xmlns:xlink="http://www.w3.org/1999/xlink"
            style={{
              margin: "auto",
              background: "transparent",
              display: "block",
              shapeRendering: "auto",
              width: "100px",
            }}
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="#6d7297"
              strokeWidth="10"
              r="35"
              strokeDasharray="164.93361431346415 56.97787143782138"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      )}
      <div
        className="ca"
        style={{
          backgroundImage: `url(${imagesPath}ca/ca_bgr.jpg)`,
        }}
      >
        {/* INTRO */}
        <div className="ca-intro">
          <div className="ca-intro-badge">
            <div className="ca-intro-badge-inner">
              <img src={`${imagesPath}ca/triangle.png`} alt="" />
              <div>
                <small>CrowningVerse</small>
              </div>
            </div>
          </div>
          <div className="ca-intro-top">
            <div className="container ca-intro-l">
              <div className="ca-intro-l-content">
                <h1>CrowningVerse</h1>
                <div className="subheading">
                  <p>A social platform with blockchain functionality.</p>
                  <p>Each planet represents a hobby or a service.</p>
                  <p>
                    Create your own planet, sell your content, get paid in
                    crypto!
                  </p>
                </div>
                <p>
                  Case study client: <small>Crowning Arts</small>
                </p>
              </div>
            </div>
            <div className="ca-intro-r">
              <img src={`${imagesPath}ca/devices.png`} alt="devices" />
            </div>
          </div>
          <div className="container ca-intro-nav">
            {intro &&
              intro.nav &&
              intro.nav.length > 0 &&
              intro.nav.map((item, i) => (
                <div className="ca-intro-nav-item" key={i}>
                  <div className="ca-intro-nav-item-cnt">
                    <Link
                      to={
                        isMobile && item.mobileSections
                          ? `${item.key}SectionM`
                          : `${item.key}Section`
                      }
                      smooth="easeInOutQuad"
                      duration={
                        isMobile ? item.scrollDurationM : item.scrollDuration
                      }
                    >
                      <div
                        className="icon"
                        // onClick={() => {
                        //   if (isMobile && item.mobileSections) {
                        //     window.scrollTo({
                        //       top: document.getElementById(`${item.key}SectionM`)
                        //         .offsetTop,
                        //       behavior: "smooth",
                        //     });
                        //   } else {
                        //     window.scrollTo({
                        //       top: document.getElementById(`${item.key}Section`)
                        //         .offsetTop,
                        //       behavior: "smooth",
                        //     });
                        //   }
                        // }}
                      >
                        {item.icon}
                      </div>
                      <p>{item.title}</p>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* DESIGN */}
        <div className="container ca-design" id="designSection">
          <div className="ca-design-content">
            <h1>Design</h1>
            <ul className="ca-list">
              <li>
                <small></small>application design
              </li>
              <li>
                <small></small>website design
              </li>
              <li>
                <small></small>design of the microsites
              </li>
              <li>
                <small></small>wireframing and prototype
              </li>
              <li>
                <small></small>mobile app design
              </li>
            </ul>
          </div>
          <div className="ca-design-bgr">
            <img
              className="default"
              src={`${imagesPath}ca/design.png`}
              alt="design"
            />
            <img
              className="mobile"
              src={`${imagesPath}ca/design_m.png`}
              alt="design"
            />
          </div>
        </div>
        {/* PLANETS */}
        <div className="container ca-planets">
          <div className="ca-divider-heading">
            <span>CrowningVerse planets</span>
          </div>
          <div className="ca-planets-list">
            <div className="ca-planets-list-item">
              <img
                src={`${imagesPath}ca/planet_music.png`}
                alt="planet music"
              />
              <p>Music</p>
            </div>
            <div className="ca-planets-list-item">
              <img
                src={`${imagesPath}ca/planet_sports.png`}
                alt="planet sport"
              />
              <p>Sports</p>
            </div>
            <div className="ca-planets-list-item">
              <img
                src={`${imagesPath}ca/planet_motors.png`}
                alt="planet motors"
              />
              <p>Motors</p>
            </div>
            <div className="ca-planets-list-item">
              <img
                src={`${imagesPath}ca/planet_science.png`}
                alt="planet science"
              />
              <p>Science</p>
            </div>
            <div className="ca-planets-list-item">
              <img src={`${imagesPath}ca/planet_food.png`} alt="planet food" />
              <p>Food and drinking</p>
            </div>
            <div className="ca-planets-list-item">
              <img src={`${imagesPath}ca/planet_pets.png`} alt="planet pets" />
              <p>Pets</p>
            </div>
            <div className="ca-planets-list-item">
              <img
                src={`${imagesPath}ca/planet_fashion.png`}
                alt="planet fashion"
              />
              <p>Fashion</p>
            </div>
            <div className="ca-planets-list-item">
              <img src={`${imagesPath}ca/planet_art.png`} alt="planet art" />
              <p>Art</p>
            </div>
            <div className="ca-planets-list-item">
              <img
                src={`${imagesPath}ca/planet_travel.png`}
                alt="planet travel"
              />
              <p>Travel</p>
            </div>
            <div className="ca-planets-list-item">
              <img src={`${imagesPath}ca/planet_sun.png`} alt="planet sun" />
              <p>Sell you content on the sun</p>
            </div>
            <div className="ca-planets-list-item">
              <img src={`${imagesPath}ca/planet_moon.png`} alt="planet moon" />
              <p>Shop on the moon</p>
            </div>
            <div className="ca-planets-list-item">
              <img
                src={`${imagesPath}ca/planet_custom.png`}
                alt="planet custom"
              />
              <p>Create your own planet</p>
            </div>
          </div>
        </div>
        {/* SUMMARY */}
        <div className="container ca-summary">
          <div className="ca-divider-heading">
            <span>...and</span>
          </div>
          <div className="ca-summary-content">
            <div className="ca-summary-list">
              <div className="ca-summary-list-item" id="codingSection">
                <img
                  src={`${imagesPath}ca/o1.png`}
                  alt="coding image"
                  className="ca-summary-list-item-img o1"
                />
                <div className="content">
                  <h3>Coding</h3>
                </div>
              </div>
              <div className="ca-summary-list-item" id="blockchainSection">
                <img
                  src={`${imagesPath}ca/o2.png`}
                  alt=" blockchain image"
                  className="ca-summary-list-item-img o2"
                />
                <div className="content">
                  <h3>Blockchain functionality</h3>
                </div>
              </div>
              <div className="ca-summary-list-item" id="researchSection">
                <img
                  src={`${imagesPath}ca/o3.png`}
                  alt="research image"
                  className="ca-summary-list-item-img o3"
                />
                <div className="content">
                  <h3>Research & analysis</h3>
                </div>
              </div>
            </div>
            <div className="ca-summary-list">
              <div
                id="codingSection"
                className="ca-summary-list-item"
                // ref={codingSection}
              >
                {/* <img
                  src={`${imagesPath}ca/o1.png`}
                  alt="coding image"
                  className="ca-summary-list-item-img-m o1"
                /> */}
                <div className="content">
                  <ul className="ca-list">
                    <li>Node.js</li>
                    <li>Express.js</li>
                    <li>Swagger.js</li>
                    <li>Mongodb</li>
                    <li>Redis</li>
                    <li>React.js</li>
                    <li>Redux.js</li>
                    <li>Ant design</li>
                    <li>Less</li>
                  </ul>
                </div>
              </div>
              <div
                id="blockchainSection"
                className="ca-summary-list-item"
                // ref={blockchainSection}
              >
                {/* <img
                  src={`${imagesPath}ca/o2.png`}
                  alt="blockchain image"
                  className="ca-summary-list-item-img-m o2"
                /> */}
                <div className="content">
                  <ul className="ca-list">
                    <li>NFT creation and minting implementation</li>
                    <li>IPFS integration</li>
                    <li>
                      Smart contract tracking of credit system transactions
                    </li>
                    <li>Blockchain transparent records of the credit system</li>
                  </ul>
                </div>
              </div>
              <div
                id="researchSection"
                className="ca-summary-list-item"
                // ref={researchSection}
              >
                {/* <img
                  src={`${imagesPath}ca/o3.png`}
                  alt="research image"
                  className="ca-summary-list-item-img-m o3"
                /> */}
                <div className="content">
                  <ul className="ca-list">
                    <li>STO structure analysis</li>
                    <li>Custom ERC20 research and proposal</li>
                    <li>Blockchain analysis and comparison</li>
                    <li>Custom crypto wallet analysis</li>
                    <li>Internal credit system structure proposal</li>
                    <li>Custodians research</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="ca-summary-content-m">
            <div className="ca-summary-list">
              <div id="codingSectionM" className="ca-summary-list-item">
                <img
                  src={`${imagesPath}ca/o1.png`}
                  alt="coding image"
                  className="ca-summary-list-item-img-m o1"
                />
                <div className="content">
                  <h3>Coding</h3>
                  <ul className="ca-list">
                    <li>Node.js</li>
                    <li>Express.js</li>
                    <li>Swagger.js</li>
                    <li>Mongodb</li>
                    <li>Redis</li>
                    <li>React.js</li>
                    <li>Redux.js</li>
                    <li>Ant design</li>
                    <li>Less</li>
                  </ul>
                </div>
              </div>
              <div id="blockchainSectionM" className="ca-summary-list-item">
                <img
                  src={`${imagesPath}ca/o2.png`}
                  alt="coding image"
                  className="ca-summary-list-item-img-m o2"
                />
                <div className="content">
                  <h3>Blockchain functionality</h3>
                  <ul className="ca-list">
                    <li>NFT creation and minting implementation</li>
                    <li>IPFS integration</li>
                    <li>
                      Smart contract tracking of credit system transactions
                    </li>
                    <li>Blockchain transparent records of the credit system</li>
                  </ul>
                </div>
              </div>
              <div id="researchSectionM" className="ca-summary-list-item">
                <img
                  src={`${imagesPath}ca/o3.png`}
                  alt="coding image"
                  className="ca-summary-list-item-img-m o3"
                />
                <div className="content">
                  <h3>Research & analysis</h3>
                  <ul className="ca-list">
                    <li>STO structure analysis</li>
                    <li>Custom ERC20 research and proposal</li>
                    <li>Blockchain analysis and comparison</li>
                    <li>Custom crypto wallet analysis</li>
                    <li>Internal credit system structure proposal</li>
                    <li>Custodians research</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER */}
        <div className="ca-footer">
          <div className="container ca-divider-heading">
            <img
              className="logo"
              src={`${imagesPath}ca/orka_logo.svg`}
              alt="logo"
            />
          </div>
          <div className="content">
            <a href="https://orka.ooo" target="_blank">
              www.orka.ooo
            </a>
          </div>
          <img
            src={`${imagesPath}ca/astronaut.png`}
            className="d"
            alt="astronaut"
          />
          <img
            src={`${imagesPath}ca/astronaut_m.png`}
            className="m"
            alt="astronaut"
          />
          <div className="cpr">
            <p>&#169; Copyright ORKA.ooo. All rights reserved.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CrowningArtsComponent;
