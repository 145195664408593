import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { isJsonString, preloadImage } from "../../../Utils";
// context
import AppContext from "../../../Store/AppContext";
import "./index.css";

function LoginPageComponent() {
  let navigate = useNavigate();
  //
  const app = useContext(AppContext);
  const password = "orkasystems2023"; // orka.systems2022

  useEffect(() => {
    if (
      isJsonString(localStorage.getItem("authorized")) &&
      JSON.parse(localStorage.getItem("authorized")) === true
    ) {
      navigate("/");
    }
  }, []);

  /**
   * Handle submit
   *
   * @param {object} e
   */
  function handleSubmit(e) {
    e.preventDefault();
    const input = e.target.passwordInput.value;

    if (input == password) {
      localStorage.setItem("authorized", true);
      navigate("/");
    } else {
      localStorage.setItem("authorized", false);
      alert("Wrong password");
    }
  }

  return (
    <div className="login-page">
      <div className="login-page-container">
        <img
          src="/assets/images/orka-systems-logo.svg"
          alt="logo"
          className="logo"
        />
        {/* <h2 className="sub-heading">ooo</h2> */}
        <div className="login-page-content">
          <form onSubmit={(e) => handleSubmit(e)} className="form">
            <input
              type="password"
              name="passwordInput"
              className="password-input"
              placeholder="Enter password"
              required
            />
            <button type="submit" className="submit">
              Ok
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPageComponent;
