import React, { useState, useEffect } from "react";

const AppContext = React.createContext({
  state: {},
  //
  setState: () => {},
});

export const AppContextProvider = (props) => {
  const [state, _setState] = useState({ imagesPath: "/assets/images/" });

  //
  useEffect(() => {
    //
  }, []);
  //

  /**
   * Set state
   */
  const setState = (key, value) => {
    _setState({ ...state, [key]: value });
  };

  return (
    <AppContext.Provider
      value={{
        state,
        //
        setState,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
