/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useContext, useRef } from "react";
import * as Scroll from "react-scroll";
// detect device
import { withOrientationChange } from "react-device-detect";
// helmet
import { Helmet } from "react-helmet";
// context
import AppContext from "../../../Store/AppContext";
// icons
import {
  LayoutLine,
  DesignIcon,
  CodingIcon,
  ResearchIcon,
  ArrowDown,
  CustomsIcons1,
  CustomsIcons2,
  CustomsIcons3,
  CustomsIcons4,
  CustomsIcons5,
  CustomsIcons6,
  CustomsIcons7,
  CustomsIcons8,
  CustomsIcons9,
  CustomsIcons10,
} from "./icons";

function ErbiaComponent(props) {
  const app = useContext(AppContext);
  const { imagesPath } = app.state;
  const customIcons = [
    {
      id: 1,
      icon: <CustomsIcons1 />,
    },
    {
      id: 2,
      icon: <CustomsIcons2 />,
    },
    {
      id: 3,
      icon: <CustomsIcons3 />,
    },
    {
      id: 4,
      icon: <CustomsIcons4 />,
    },
    {
      id: 5,
      icon: <CustomsIcons5 />,
    },
    {
      id: 6,
      icon: <CustomsIcons6 />,
    },
    {
      id: 7,
      icon: <CustomsIcons7 />,
    },
    {
      id: 8,
      icon: <CustomsIcons8 />,
    },
    {
      id: 9,
      icon: <CustomsIcons9 />,
    },
    {
      id: 10,
      icon: <CustomsIcons10 />,
    },
  ];
  const colors = [
    { text: "Gold primary", class: "gold-primary" },
    { text: "Gold gradient", class: "gold-gradient" },
    { text: "Elevation", class: "elevation" },
    { text: "Background", class: "background" },
  ];

  useEffect(() => {
    const erbiaEl = document.querySelector(".erbia");
    if (isLandscapeView()) {
      erbiaEl.classList.add("erbia-landscape");
    } else {
      erbiaEl.classList.remove("erbia-landscape");
    }
  }, [props.isLandscape]);

  /**
   * Is landscape view
   *
   * @returns bool
   */
  function isLandscapeView() {
    // general
    const breakpoints = {
      xs: 480,
      sm: 560,
      md: 767,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    };

    return (
      (props.isLandscape && window && window.innerHeight < breakpoints.sm) ||
      (typeof window !== "undefined" && window.innerHeight < breakpoints.sm)
    );
  }

  function handleScrollDown() {
    const resourcesSection = document.querySelector(".erbia-resources");
    // window.scrollTo({ top: resourcesSection.offsetTop, behavior: "smooth" });
    Scroll.animateScroll.scrollTo(resourcesSection.offsetTop - 50, {
      duration: 800,
      //smooth: "easeOutQuart",
      smooth: true,
      // ignoreCancelEvents: true,
    });
  }

  function renderColorsSection() {
    return (
      <div className="erbia-colors">
        <h1 className="erbia-heading">
          <span>Colors</span>
        </h1>
        <div className="erbia-colors-items">
          {colors.map((color, index) => (
            <div className="item" key={index}>
              <div className={`item-color ${color.class}`}></div>
              <p>{color.text}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderFontsSection() {
    return (
      <div className="erbia-fonts">
        <h1 className="erbia-heading">
          <span>Fonts</span>
        </h1>
        <div className="erbia-fonts-content">
          <div className="abhaya">
            <h2>Abhaya libre</h2>
            <p>
              a, á, ä, b, c, č, d, ď, dz, dž, e, é, f, g, h, ch, i, í, j, k, l,
              ĺ, ľ, m, n, ň, o, ó, ô
            </p>
            <p>
              a, á, ä, b, c, č, d, ď, dz, dž, e, é, f, g, h, ch, i, í, j, k, l,
              ĺ, ľ, m, n, ň, o, ó, ô
            </p>
          </div>
          <div className="inter">
            <h2>Inter</h2>
            <p>
              a, á, ä, b, c, č, d, ď, dz, dž, e, é, f, g, h, ch, i, í, j, k, l,
              ĺ, ľ, m, n, ň, o, ó, ô
            </p>
            <p>
              a, á, ä, b, c, č, d, ď, dz, dž, e, é, f, g, h, ch, i, í, j, k, l,
              ĺ, ľ, m, n, ň, o, ó, ô
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <Helmet>
  
        <title>ORKA.ooo | Case Study - ERBIA</title>
        <meta name="description" content="ERBIA - Prestige Business Club" />

  
       <meta
          content="ORKA.ooo | Case Study - ERBIA"
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content="ERBIA - Prestige Business Club"
          property="og:description"
          data-react-helmet="true"
        />
        <meta
          content="/favs/webclip-ooo.png"
          property="og:image"
          data-react-helmet="true"
        /> 


        <meta
          content="ORKA.ooo | Case Study - ERBIA"
          property="twitter:title"
          data-react-helmet="true"
        />
        <meta
          content="ERBIA - Prestige Business Club"
          property="twitter:description"
          data-react-helmet="true"
        />
        <meta
          content="/favs/webclip-ooo.png"
          property="twitter:image"
          data-react-helmet="true"
        /> 
      </Helmet> */}
      <div className="erbia">
        <section className="erbia-section erbia-intro">
          <div className="erbia-section-content">
            <div className="erbia-intro-content">
              <img className="logo" src={`${imagesPath}erbia/logo.png`} />
              <h1>Prestige business club</h1>
              <ArrowDown onClick={handleScrollDown} />
            </div>
          </div>
        </section>
        {/* RESOURCES */}
        <div className="erbia-main">
          <section className="erbia-section erbia-resources">
            <div className="erbia-section-content">
              <h1 className="erbia-heading erbia-resources-h">
                <span>Resources</span>
              </h1>
              <div className="erbia-resources-items">
                <div className="item">
                  <div className="item-icon">
                    <div className="item-icon-inner">
                      <DesignIcon />
                    </div>
                  </div>
                  <p>Design</p>
                </div>
                <div className="item">
                  <div className="item-icon">
                    <div className="item-icon-inner">
                      <CodingIcon />
                    </div>
                  </div>
                  <p>Coding</p>
                </div>
                <div className="item">
                  <div className="item-icon">
                    <div className="item-icon-inner">
                      <ResearchIcon />
                    </div>
                  </div>
                  <p>
                    Research, analysis &<br /> concept
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* CUSTOM ICONS */}
          <section className="erbia-section erbia-custom">
            <img
              className="erbia-custom-bck"
              src={`${imagesPath}erbia/gradient-center.png`}
            />
            <div className="erbia-section-content">
              <h1 className="erbia-heading">
                <span>Custom icons</span>
              </h1>
              <div className="erbia-custom-items">
                {customIcons.map((item) => (
                  <div className="item" key={item.id}>
                    <div className="item-icon">
                      <div className="item-icon-inner">{item.icon}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* MODE */}
          <section className="erbia-section erbia-mode">
            <div className="erbia-section-content erbia-mode-content">
              <h1 className="erbia-heading">
                <span>Light mode / Dark mode</span>
              </h1>
              <img
                src={`${imagesPath}erbia/theme-mode.png`}
                className="erbia-mode-img"
              />
              <div className="erbia-mode-mobile">
                <div className="login-form">
                  <img src={`${imagesPath}erbia/login-form-dark.png`} />
                  <img src={`${imagesPath}erbia/login-form-light.png`} />
                </div>
                {renderColorsSection()}
                {renderFontsSection()}
              </div>

              <div className="erbia-mode-desktop">
                <div className="group group-top">
                  <div className="group-item">
                    <img src={`${imagesPath}erbia/login-group-dark.png`} />
                  </div>
                  <div className="group-item">{renderColorsSection()}</div>
                </div>
                <div className="group group-bottom">
                  <div className="group-item">{renderFontsSection()}</div>
                  <div className="group-item">
                    <img src={`${imagesPath}erbia/login-group-light.png`} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* CLIENTS */}
          <section className="erbia-section erbia-clients">
            <div className="erbia-section-content erbia-clients-content">
              <h1 className="erbia-heading">
                <span>Members</span>
              </h1>
              <img src={`${imagesPath}erbia/clients.png`} className="mobile" />
              <img src={`${imagesPath}erbia/clients.png`} className="desktop" />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default withOrientationChange(ErbiaComponent);
